/* eslint-disable @typescript-eslint/no-var-requires */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  CheckoutRequestPlan,
  LicenseDto,
  PlanTerm,
} from '@gentext/api-psychology-client';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { BillingDialogService } from '../billing-dialog.service';
import { LicenseService } from '../license.service';

interface PlanSelection {
  title: string;
  price: number | undefined;
  oldPrice?: number;
  planType: 'free' | 'premium' | 'enterprise';
}
@UntilDestroy()
@Component({
  selector: 'gentext-manage-plan',
  templateUrl: './manage-plan.component.html',
  styleUrls: ['./manage-plan.component.css'],
  standalone: false,
})
export class ManagePlanComponent implements OnInit, AfterViewInit {
  PlanTerm = PlanTerm;

  loading$ = this.billingDialogService.loading$;

  hasUnexpectedError$ = this.billingDialogService.hasUnexpectedError$;
  hasBadRequestError$ = this.billingDialogService.hasBadRequestError$;
  resolveResponse$ = this.billingDialogService.resolveResponse$;
  selectedPlanTerm = PlanTerm.Monthly;
  selectedPlanId: string | undefined;
  get currentPlanId() {
    return this.currentLicense?.planId;
  }
  get currentPlanTerm() {
    return this.currentLicense?.planTerm;
  }
  get customerId() {
    return this.currentLicense?.customerId;
  }

  get isManagePlanDisabled$() {
    return this.loading$.pipe(
      map((loading) => {
        if (loading) {
          return true;
        }

        if (this.selectedPlanId === this.currentPlanId) {
          if (this.selectedPlanId === 'free') {
            // if currently free and selected free => disabled
            return true;
          }
          // disabled if same term
          return this.currentPlanTerm === this.selectedPlanTerm;
        }
        return false;
      }),
    );
  }
  planSelections: PlanSelection[] = [];
  private currentLicense: LicenseDto | undefined;
  get monthlyAppearance() {
    return this.selectedPlanTerm === PlanTerm.Monthly ? 'accent' : 'outline';
  }
  get yearlyAppearance() {
    return this.selectedPlanTerm === PlanTerm.Yearly ? 'accent' : 'outline';
  }
  get backAppearance() {
    return 'filled';
  }

  get changePlanButtonText() {
    if (this.selectedPlanId === 'free') {
      return 'Manage';
    } else if (this.selectedPlanId === 'premium') {
      if (this.currentPlanId === 'free') {
        return 'Upgrade';
      } else {
        if (this.selectedPlanTerm !== this.currentPlanTerm) {
          return 'Change term';
        }
      }
    } else if (this.selectedPlanId === 'enterprise') {
      return 'Contact us';
    }
    return 'Upgrade';
  }

  goBack() {
    this.router.navigateByUrl('/chat');
  }
  setPlanTerm(planTerm: PlanTerm) {
    this.selectedPlanTerm = planTerm;
    this.updatePlanSelections();
  }
  upgrade() {
    let plan: CheckoutRequestPlan;
    if (this.selectedPlanId === 'enterprise') {
      window.open('mailto:support@mentalnote.ai');
      return;
    }
    if (
      this.selectedPlanId === 'free' ||
      this.currentPlanId !== 'free' ||
      this.currentLicense?.hasPendingInvoice
    ) {
      // if selecting free, OR currently on a non-free plan, OR when there's a pending invoice
      this.billingDialogService.showManagePlanDialog();
      return;
    }
    if (this.selectedPlanTerm === 'Monthly') {
      plan = CheckoutRequestPlan.PremiumMonthly;
    } else {
      plan = CheckoutRequestPlan.PremiumYearly;
    }
    this.billingDialogService.showUpgradeDialog(
      plan,
      undefined,
      this.currentLicense?.customerId || undefined,
    );
  }
  constructor(
    private licenseService: LicenseService,
    private billingDialogService: BillingDialogService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.billingDialogService.clearResponse();
    this.updatePlanSelections();
    this.updateSelectedPlanTerm();
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.licenseService.license$.pipe(untilDestroyed(this)).subscribe((l) => {
      this.currentLicense = l;
      this.selectedPlanId = l?.planId;
    });
  }

  private updateSelectedPlanTerm() {
    if (this.currentLicense?.planTerm) {
      this.setPlanTerm(this.currentLicense.planTerm);
    }
  }

  private updatePlanSelections() {
    this.planSelections = [
      {
        title: 'Free Plan',
        price: 0,
        planType: 'free',
      },
      {
        title: 'Premium Plan',
        price: this.selectedPlanTerm === 'Monthly' ? 99 : 990,
        oldPrice: this.selectedPlanTerm === 'Yearly' ? 99 * 12 : undefined,
        planType: 'premium',
      },
      {
        title: 'Enterprise',
        planType: 'enterprise',
        price: undefined,
      },
    ];
  }
}
