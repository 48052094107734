import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  CheckoutRequestPlan,
  FeatureFlagService,
  LicenseDto,
} from '@gentext/api-psychology-client';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BillingDialogService } from '../billing-dialog.service';
import { LicenseService } from '../license.service';
type OFFER_OPTION = 1 | 2 | 3;
@UntilDestroy()
@Component({
  selector: 'gentext-business-plan-offer',
  templateUrl: './business-plan-offer.component.html',
  styleUrls: ['./business-plan-offer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BusinessPlanOfferComponent implements OnInit {
  isBusinessOfferEnabled = false;
  offerVisible: OFFER_OPTION | undefined = undefined;
  loading$ = this.billingDialogService.loading$;
  private currentLicense: LicenseDto | undefined;

  get offerTitle() {
    const currentMonth = new Date().getMonth() + 1;
    switch (currentMonth) {
      case 10:
        return 'Fall Focus Discount';
      case 11:
        return 'Black Friday Offer';
      case 12:
        return 'Holiday Calm Special';
      case 1:
        return 'New Year Offer';
      default:
        return 'Premium Offer';
    }
  }

  constructor(
    private featureService: FeatureFlagService,
    private licenseService: LicenseService,
    private loggingService: LoggingService,
    private billingDialogService: BillingDialogService,
    private cdr: ChangeDetectorRef,
  ) {}
  goToOffer(offerOption: OFFER_OPTION) {
    this.loggingService.trace({
      message: `Opening offer option ${offerOption}, customerId: ${this.currentLicense?.customerId}`,
    });
    this.billingDialogService.showUpgradeDialog(
      CheckoutRequestPlan.PremiumYearly,
      'BUSINESSOFFER',
      this.currentLicense?.customerId || undefined,
    );
  }
  ngOnInit() {
    this.licenseService.license$
      .pipe(untilDestroyed(this))
      .subscribe((license) => {
        this.currentLicense = license;
        this.checkVisibility(license);
      });
  }
  private checkVisibility(license: LicenseDto | undefined) {
    if (license?.planId !== 'free') {
      this.loggingService.trace({
        message: 'Business Plan Offer - not free plan',
        severityLevel: SeverityLevel.Information,
      });
      this.isBusinessOfferEnabled = false;
      this.cdr.detectChanges();
      return;
    }
    this.featureService.featureFlagGet('business-offer-psychology').subscribe({
      next: (res) => {
        this.loggingService.trace({
          message: `Business Plan Offer - enabled: ${res}`,
          severityLevel: SeverityLevel.Information,
        });
        this.isBusinessOfferEnabled = res.overriddenIsEnabled ?? res.isEnabled;
        if (this.isBusinessOfferEnabled) {
          this.setVisibleOffer();
        }
        this.cdr.detectChanges();
      },
      error: () => {
        this.isBusinessOfferEnabled = false;
        this.cdr.detectChanges();
      },
    });
  }

  private setVisibleOffer() {
    this.checkOfferVisible(1);
  }
  private checkOfferVisible(offer: OFFER_OPTION) {
    this.featureService
      .featureFlagGet(`business-offer-${offer}`)
      .subscribe((res) => {
        if (res) {
          this.offerVisible = offer;
        } else if (offer === 1) {
          this.checkOfferVisible(2);
        } else if (offer === 2) {
          this.checkOfferVisible(3);
        } else {
          this.loggingService.trace({
            message: 'Business Plan Offer - unexpectedly no offer type enabled',
            severityLevel: SeverityLevel.Warning,
          });
        }
        this.cdr.detectChanges();
      });
  }
}
